// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academic-js": () => import("./../../../src/pages/academic.js" /* webpackChunkName: "component---src-pages-academic-js" */),
  "component---src-pages-academic-trial-js": () => import("./../../../src/pages/academic_trial.js" /* webpackChunkName: "component---src-pages-academic-trial-js" */),
  "component---src-pages-academic-trial-video-js": () => import("./../../../src/pages/academic_trial_video.js" /* webpackChunkName: "component---src-pages-academic-trial-video-js" */),
  "component---src-pages-academic-video-demo-js": () => import("./../../../src/pages/academic_video_demo.js" /* webpackChunkName: "component---src-pages-academic-video-demo-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-enterprise-js": () => import("./../../../src/pages/enterprise.js" /* webpackChunkName: "component---src-pages-enterprise-js" */),
  "component---src-pages-gdpr-js": () => import("./../../../src/pages/gdpr.js" /* webpackChunkName: "component---src-pages-gdpr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-personal-js": () => import("./../../../src/pages/personal.js" /* webpackChunkName: "component---src-pages-personal-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-privacy-shield-js": () => import("./../../../src/pages/privacy-shield.js" /* webpackChunkName: "component---src-pages-privacy-shield-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

