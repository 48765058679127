/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.onRouteUpdate = () => {
  window.analytics && window.analytics.page()
}

exports.shouldUpdateScroll = (routerProps, pathname) =>
{
  return !["/academic", "/enterprise", "/personal"].includes(routerProps.pathname)
}
